import React from 'react';
import { Spin,message} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { HttpPost } from '../common/httpRequest';

import './nameDetail.css';

class NameDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            name_id:'',
            order_sn:'',
            order_token:'',
            name_detail:null,
            loading:true,
            is_send:false,

        }
        this.timer = null;
        this.ptimer = null;
        console.log(this.props)
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.nameId) {
            this.setState({name:this.props.location.state.name,name_id:this.props.location.state.nameId,order_sn:this.props.location.state.order_sn,order_token:this.props.location.state.order_token})
            this.getNameInfo(this.props.location.state.nameId, this.props.location.state.order_sn, this.props.location.state.order_token);
        }else {
            message.error('获取数据失败，请返回上一页重试');
        }
    }

    componentWillUnmount() {
        this.ptimer && clearInterval(this.ptimer);
        this.timer && clearTimeout(this.timer);
    }

    getNameInfo = async (name_id, order_sn, order_token) => {
        this.ptimer = setInterval(() => this.getNameDetail(name_id, order_sn, order_token), 3000);
        this.timer = setTimeout(
            () => {
                this.setState({loading:false});
                this.ptimer && clearInterval(this.ptimer);
                this.timer && clearTimeout(this.timer);
                message.error('获取推荐美名信息失败，请联系客服！');
            },
            14000
        );
    }

    getNameDetail = async (name_id, order_sn, order_token) => {
        let postData = {
            name_id: name_id,
            order_sn:order_sn,
            order_token:order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/order/name-detail',postData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4136) {
                this.setState({loading:false});
                this.ptimer && clearInterval(this.ptimer);
                this.timer && clearTimeout(this.timer);
                this.setState({name_detail: retData.data.nameDetail});
            }
            // else {
            //     message.error(retData.message ? retData.message : '获取推荐美名信息失败，请联系客服！');
            // }
        }else {
            // this.ptimer && clearInterval(this.ptimer)
            // message.error('获取推荐美名信息失败，请联系客服！');
        }
    }

    goBack = () => {
        this.props.history.push("/result", {order_sn:this.state.order_sn,order_token: this.state.order_token,show_page:true});
        // this.props.history.goBack(-1);
    }

    render() {
        return (
            <div className="mb-name-detail">
            <div className="mb-nd-d">
                <div className="mb-nd-t">
                    <div className="mb-nd-tl" onClick={this.goBack}><LeftOutlined /></div>
                    <div className="mb-nd-tt">{this.state.name} 名字分析</div>
                    <div  className="mb-nd-tl"></div>
                </div>
                {this.state.loading ? <Spin tip="Loading..." spinning={this.state.loading}></Spin> :
                (this.state.name_detail ?
                <div>
                    <div>
                        <div>
                        <div className="mb-nd-db">
                            <div className="mb-na-db-s">
                                <div className="mb-na-db-sa">五格得分:<span className="mb-na-db-sb">{this.state.name_detail ? this.state.name_detail.score : ''}</span>分</div>
                            </div>
                            <div className="mb-nd-db-t">名字分析</div>
                            <div className="mb-nd-db-tt">
                                <table border="1px">
                                    <tr>
                                        <td className="mb-nd-db-tt-td">姓名</td>
                                        <td className="mb-nd-db-tt-td">繁体</td>
                                        <td className="mb-nd-db-tt-td">康熙</td>
                                        <td className="mb-nd-db-tt-td">简笔画</td>
                                        <td className="mb-nd-db-tt-td">繁笔画</td>
                                        <td className="mb-nd-db-tt-td">康熙笔画</td>
                                        <td className="mb-nd-db-tt-td">五行</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.name_detail ? this.state.name_detail.first : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.f_o_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.f_k_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.f_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.f_o_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.f_k_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.first_elem : ''}</td>
                                    </tr>
                                    <tr>
                                    <td>{this.state.name_detail ? this.state.name_detail.second : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.s_o_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.s_k_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.s_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.s_o_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.s_k_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.second_elem : ''}</td>
                                    </tr>
                                    <tr>
                                    <td>{this.state.name_detail ? this.state.name_detail.third : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.t_o_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.t_k_name : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.t_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.t_o_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.t_k_strokes : ''}</td>
                                        <td>{this.state.name_detail ? this.state.name_detail.third_elem : ''}</td>
                                    </tr>
                                </table>
                                </div>
                                <div className="mb-nd-db-t">五格剖象</div>
                                <div className="mb-nd-db-tt">
                                    <table border="1px">
                                        <tr>
                                            <td className="mb-nd-db-tt-td"></td>
                                            <td className="mb-nd-db-tt-td">天格</td>
                                            <td className="mb-nd-db-tt-td">人格</td>
                                            <td className="mb-nd-db-tt-td">地格</td>
                                            <td className="mb-nd-db-tt-td">外格</td>
                                            <td className="mb-nd-db-tt-td">总格</td>
                                        </tr>
                                        <tr>
                                            <td className="mb-nd-db-tt-td">格数</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.t_ge : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.r_ge : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.d_ge : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.w_ge : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.z_ge : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="mb-nd-db-tt-td">五行</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.t_ge_e : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.r_ge_e : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.d_ge_e : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.w_ge_e : ''}</td>
                                            <td>{this.state.name_detail ? this.state.name_detail.z_ge_e : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="mb-nd-db-tt-td">吉凶</td>
                                            <td>{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.tge.luck : ''}</td>
                                            <td>{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.rge.luck : ''}</td>
                                            <td>{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.dge.luck : ''}</td>
                                            <td>{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.wge.luck : ''}</td>
                                            <td>{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.zge.luck : ''}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-nd-db-tr">
                                    <div className="mb-nd-db-trt">五格说明</div>
                                    <div className="mb-nd-db-trd">天格：又称先格，主掌祖传运势。祖先留下来的，对人影响不大。</div>
                                    <div className="mb-nd-db-trd">人格：又称主运，整个姓名的中心点，决定人一生的命运。</div>
                                    <div className="mb-nd-db-trd">地格：又称前运，主管人中年以前，求学与事业初期的机运。</div>
                                    <div className="mb-nd-db-trd">外格：又称副运，主掌中年事业成熟稳定时期的机运。</div>
                                    <div className="mb-nd-db-trd">总格：又称后运，主掌中年至晚年的命运。</div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="mb-nd-w">
                        <div className="mb-nd-wt">五格数理剖析</div>
                    </div> 
                    <div className="mb-nd-wg">
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">天格（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.tge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">诗曰：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.poetry}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">数理：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.math}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">基业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.base}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">含义：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.tge.meaning}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：天格是由姓决定的，寓意思想、智慧以及与长辈领导的关系，主要影响12岁以前，以及37-48岁的运势。</div>
                        </div>
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">人格（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.rge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">诗曰：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.poetry}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">数理：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.math}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">基业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.base}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">含义：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.rge.meaning}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：人格又称主运，是姓名的中心点，寓意性情、婚姻、兄弟姐妹、健康、情绪等，主要影响13-48岁的运势。</div>
                        </div>
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">地格（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.dge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">诗曰：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.poetry}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">数理：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.math}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">基业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.base}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">含义：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.dge.meaning}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：地格又称前运，寓意工作运、财运、部属/子女/夫妻关系，主要影响25岁之前的运势。</div>
                        </div>
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">外格（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.rge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">诗曰：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.poetry}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">数理：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.math}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">基业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.base}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">含义：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.wge.meaning}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：外格又称变格，寓意社交关系，主要影响25-36岁的社交运势。其数理不用重点去看。</div>
                        </div>
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">总格（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.rge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">诗曰：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.poetry}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">数理：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.math}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">基业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.base}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">含义：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.zge.meaning}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：总格又称后运，影响人中年（36岁）以后的命运</div>
                        </div>
                    </div>
                    <div className="mb-nd-w">
                        <div className="mb-nd-wt">三才剖析</div>
                    </div>
                    <div className="mb-nd-wg">
                        <div className="mb-nd-wg-t">
                            <div className="mb-nd-wg-tt">三才配置（{this.state.name_detail && this.state.name_detail.test_detail ? this.state.name_detail.test_detail.tge.luck : ''}）</div>
                            <div className="mb-nd-wg-tn">
                                <div className="mb-nd-wg-tndt">详细解释：　<span className="mb-nd-wg-tndjg">[仅供参考]</span></div>
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">三才：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.sancai}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">解析：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.analysis}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">性格：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.character}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">意志：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.will}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">事业：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.cause}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">家庭：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.family}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">健康：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.healthy}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">婚姻：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.marriage}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">子女：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.children}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">社交：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.social}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">精神：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.spirit}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">财运：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.fortune}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">老运：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.oldfortune}</div></div> : ''}
                                {this.state.name_detail && this.state.name_detail.test_detail ? <div className="mb-nd-wg-tnd"><div className="mb-nd-wg-tndl">总论：</div><div className="mb-nd-wg-tndr">{this.state.name_detail.test_detail.sancai.general}</div></div> : ''}
                            </div>
                            <div className="mb-nd-wg-tndd">注：三才即天人地三才，分别是天人地三格的数理配置组合，反映综合内在运势。</div>
                            <div className="mb-nd-tipss">警告：以上所有信息来源于网络，仅供参考。</div>
                        </div>
                    </div>
                </div> : '')
                }
            </div>
            </div>
        );
    }
}

export default NameDetail;