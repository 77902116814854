import React from 'react';
import { message, Input, Form, Select, Button,Radio } from 'antd';
import { HttpPost } from '../common/httpRequest';

import pc_bg_img from '../../images/pc_bg.jpg';
import bg_main from '../../images/bg_main.png';
import pc_ys8 from '../../images/ys_8.png';
import pc_jj_img from '../../images/pc_jj.png';
import pc_cjwt from '../../images/pc_cjwt.png';
import pc_sysm from '../../images/pc_sysm.png';
import pc_lg_adv from '../../images/pc_lg_adv.jpg';
import pc_bbqi from '../../images/pc_bbqi.png';
import pc_input_img from '../../images/pc_in_pt.png';
// import qrcode from '../../images/baijiaqm.jpg';

import './pcHome.css';

class PCHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_send:false,
            total:'00000',
            showWord:false,
            wordType:'1',
            nameType:'',
            gender:'',
            year:'',
            month:'',
            day:'',
            hour:'未知',
            minute:'未知',
            selectMinutes:[],
            selectHours:[],
            selectDays:[],
            selectMonths:[],
            selectYears:[]
        }

        this.htimer = null;
        console.log(this.props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.dealDateInfo();
        // this.htimer = setInterval(() => this.getUserTotal(), 5000);
    }

    componentWillUnmount() {
        
        clearInterval(this.htimer);
    }

    dealDateInfo = () => {
        let maxDay = 31;
        let arrMinute = [];
        let arrHour = [];
        let arrDay = [];
        let arrMonth = [];
        let arrYear = []
        
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        for (let y = year + 1; y > 1969; y--) {
            arrYear.push(y.toString());
        }

        for (let m = 1; m <= 12; m++) {
            arrMonth.push(m < 10 ? '0' + m.toString() : m);
        }

        maxDay = this.setDayInfo(year, month)
        for (let d = 1; d <= maxDay; d++) {
            arrDay.push(d < 10 ? '0' + d.toString() : d);
        }

        for (let h = 0; h < 24; h ++) {
            arrHour.push(h < 10 ? '0' + h.toString() : h);
        }

        for (let u = 0; u < 60; u ++) {
            arrMinute.push(u < 10 ? '0' + u.toString() : u);
        }

        this.setState({
            year:year.toString(),
            month:month < 10 ? '0' + month : month.toString(),
            day:day < 10 ? '0' + day : day.toString(),
            selectYears: arrYear,
            selectMonths: arrMonth,
            selectDays:arrDay,
            selectHours: arrHour,
            selectMinutes:arrMinute
        })
    }

    setDayInfo = (year, month) => {
        if (month === 2) {
            if (year % 4 === 0) {
                return 29;
            }else {
                return 28;
            }
        }

        if (month < 8) {
            if (month % 2 === 0) {
                return 30;
            }else {
                return 31;
            }
        }else if (month > 7) {
            if (month % 2 === 0) {
                return 31;
            }else {
                return 30;
            }
        }

        return 30;
    }

    onChangeYear = (year) => {
        this.setState({year:year});
    }

    onChangeMonth = (month) => {
        let arrDay = [];
        
        let days = this.setDayInfo(this.state.year, month);
        for (let d = 1; d <= days; d++) {
            arrDay.push(d < 10 ? '0' + d.toString() : d);
        }
        
        this.setState({month:month, selectDays:arrDay});
    }
    onChangeDay = (day) => {
        this.setState({day:day});
    }
    onChangeHour = (hour) => {
        this.setState({hour:hour});
    }
    onChangeMinute = (minute) => {
        this.setState({minute:minute});
    }

    onChangeGender = (v) => {
        console.log(v.target.value)
        this.setState({gender:v.target.value});
    };

    onChangeNameType = (v) => {
        console.log(v.target.value)
        this.setState({nameType:v.target.value});
    };

    onChangeWordType = (v) => {
        console.log(v.target.value)
        if (v.target.value === '0') {
            this.setState({wordType:v.target.value, showWord:false});
        }else {
            this.setState({wordType:v.target.value, showWord:true});
        }
    };

    getUserTotal = async () => {
        if (this.state.is_send) {
            return ;
        }
        this.setState({is_send:true});
        
        let postData = {
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/index.php?r=statistics/get-user-total',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 60015) {
                this.setState({total:retData.data.total})
            }else {
                console.log(retData);
            }
        }
        this.setState({is_send:false});
    }

    onFinish = async (data) => {
        console.log(data);
        console.log(this.state.year + '-' + this.state.month + '-' + this.state.day + ' ' + this.state.hour + ':' + this.state.minute + ':00');
        if (this.state.is_send) {
            return false;
        }
        this.setState({loading:true, is_send:true});

        if (!data.surname) {
            message.error('请输入取名姓名');
            this.setState({loading:false, is_send:false});
            return false;
        }

        if (!data.gender) {
            message.error('请选择起名宝宝性别');
            this.setState({loading:false, is_send:false});
            return false;
        }

        if (!data.name_type) {
            message.error('请选择名字类型');
            this.setState({loading:false, is_send:false});
            return false;
        }

        if (data.word_type && data.word_type > 1 && !data.word) {
            message.error('请输入固定用字');
            this.setState({loading:false, is_send:false});
            return false;
        }

        if (data.word_type && data.word_type > 1) {
            if (data.word_type === 2 && data.name_type === 1) {
                message.error('名字类型与固定字类型冲突');
                return false;
            }else if (data.word_type === 3 && data.name_type === 1) {
                message.error('名字类型与固定字类型冲突');
                return false;
            }
        }

        let postData = {
            surname: data.surname,
            gender: data.gender,
            name_type: data.name_type,
            word_type: data.word_type,
            word: data.word,
            year: this.state.year,
            month: this.state.month,
            day: this.state.day,
            hour: this.state.hour,
            minute: this.state.minute,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        console.log('sendData', postData);
        let retData = await HttpPost('/order/create-order',postData);
        console.log(retData);

        if (retData && retData.code) {
            if (parseInt(retData.code) === 4114) {
                this.props.history.push("/pay", retData.data);
            } else {
                message.error(retData.message ? retData.message : '操作失败！');
            }
        }else {
            message.error('操作失败！');
        }

        this.setState({loading:false, is_send:false});
    };

    goAboutMe = () => {
        this.props.history.push("/aboutme");
    }

    render() {
        return (
            <div id="app-pc" className="App-pc">
                <div className="pc-body" style={{backgroundImage:`url(${pc_bg_img})`}}>
                    <div className="pc-bo-c" style={{width:1024, backgroundImage:`url(${bg_main})`}}>
                        <div style={{borderColor:'red', borderWidth:2}}><img src={pc_lg_adv} alt="none"></img></div>
                        <div style={{borderColor:'red', borderWidth:2}}><img src={pc_bbqi} alt="none"></img></div>
                        <div className="pc-bo-i" style={{width:1024,height:500,backgroundImage:`url(${pc_input_img})`, backgroundSize:1024, backgroundRepeat:'no-repeat',}}>
                            <Form className="pc-bo-f" name="control-hooks" labelCol={{span:3, offset:1}} ref={this.formRef} onFinish={this.onFinish} layout="inline">
                                <Form.Item name="surname" label="姓  氏">
                                    <Input style={{width:210,height:40,right:130}}/>
                                </Form.Item>
                                <div style={{height:15}}></div>
                                <Form.Item name="gender" label="性  别">
                                    <div style={{width:200,height:40, paddingTop:5, right:300}} >
                                        <Radio.Group onChange={this.onChangeGender} value={this.state.gender}>
                                            <Radio value="1">男孩</Radio>
                                            <Radio value="2">女孩</Radio>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>
                                <div style={{height:15}}></div>
                                <Form.Item name="birthday" label="出生日期">
                                    <div style={{width:520,height:40}}>
                                        <div style={{marginRight:10,display:'inline'}}>
                                            <Select style={{width: 75}} value={this.state.year} onChange={this.onChangeYear} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                {this.state.selectYears.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                                            </Select>
                                            <div style={{marginLeft:10,display:'inline'}}>年</div>
                                        </div>
                                        <div style={{marginRight:10,display:'inline'}}>
                                            <Select style={{width: 60}} value={this.state.month} onChange={this.onChangeMonth} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                {this.state.selectMonths.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                                            </Select>
                                            <div style={{marginLeft:10,display:'inline'}}>月</div>
                                        </div>
                                        <div style={{marginRight:10,display:'inline'}}>
                                            <Select style={{width: 60}} value={this.state.day} onChange={this.onChangeDay} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                {this.state.selectDays.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                                            </Select>
                                            <div style={{marginLeft:10,display:'inline'}}>日</div>
                                        </div>
                                        <div style={{marginRight:10,display:'inline'}}>
                                            <Select style={{width: 70}} value={this.state.hour} onChange={this.onChangeHour} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                <Select.Option value='未知'>未知</Select.Option>
                                                {this.state.selectHours.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                                            </Select>
                                            <div style={{marginLeft:10,display:'inline'}}>时</div>
                                        </div>
                                        <div style={{marginRight:10,display:'inline'}}>
                                            <Select style={{width: 70}} value={this.state.minute} onChange={this.onChangeMinute} getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                <Select.Option value='未知'>未知</Select.Option>
                                                {this.state.selectMinutes.map((item) => <Select.Option value={item}>{item}</Select.Option>)}
                                            </Select>
                                            <div style={{marginLeft:10,display:'inline'}}>分</div>
                                        </div>
                                    </div>
                                </Form.Item>
                                <div style={{height:10}}></div>
                                <Form.Item name="name_type" label="名字类型">
                                    <div style={{width:200,height:40,paddingTop:5,right:300}} >
                                        <Radio.Group onChange={this.onChangeNameType} value={this.state.nameType}>
                                            <Radio value="1">单字名</Radio>
                                            <Radio value="2">双字名</Radio>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>
                                <div style={{height:10}}></div>
                                <Form.Item name="word_type" label="用固定字">
                                    <div style={{width:320,right:200}} >
                                        <Radio.Group style={{marginTop:12,display:'inline'}} onChange={this.onChangeWordType} value={this.state.wordType}>
                                            <Radio value="1">不使用固定字</Radio>
                                            <Radio value="2">中间字</Radio>
                                            <Radio value="3">末尾字</Radio>
                                        </Radio.Group>
                                    </div>
                                </Form.Item>
                                
                                <div style={{height:60}}>
                                    <Form.Item name="word" label="固定用字"  style={this.state.showWord ?{} : {display:'none'}}>
                                        <div style={{width:105,right:200}} >
                                            <Input style={{width:90,height:40}}/>
                                        </div>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Button style={{backgroundColor: '#c20523',border:1,width:350, height:55, fontSize:26}} type="primary" htmlType="submit" loading={this.state.loading}>立即获取100个吉祥美名</Button>
                                </div>
                            </Form>
                        </div>
                        <div style={{borderColor:'red', borderWidth:2}}><img src={pc_ys8} alt="none"></img></div>
                        <div style={{borderColor:'red', borderWidth:2}}><img src={pc_jj_img} alt="none"></img></div>
                        <div className="pc-jj-d">
                            {/* <p className="pc-jj-dp">姓名对一个人一生的命运、事业、健康等都有莫大的影响，一个好名字是送给宝宝来到这个世界上最好的礼物。</p>
                            <p></p> */}
                            {/*录属于深圳市景辰信息技术有限公司的宝宝起名网站，*/}
                            <p className="pc-jj-dp"><span style={{fontWeight:600}}>落名阁</span>，一个深耕于宝宝起名多年，服务十多万的父母给宝宝赐名的网站，由深圳市景辰信息技术有限公司提供技术支持。我们根据多年的起名经验发现：不同的父辈由于家庭背景、学历、阅历和喜好的不同，对同一个好名可能会有不同印象和满意度，我们的专业人工起名团队很难一次起名就可以达到父母满意度，因此，应部分父母的强烈要求，我们的专业宝宝起名团队，通过多年对周易、国学、生肖和三才五格深入的学习研究，结合多年起名实践经验，利用最新的大数据筛选方法，从亿级姓名中层层筛选出部分三才五格优异、音形义俱佳的好名，提供给宝妈宝爸们甄选。
                            </p>
                        </div>
                        <div style={{borderColor:'red', borderWidth:2,marginTop:40, marginBottom:10}}><img src={pc_cjwt} alt="none"></img></div>
                        <div className="pc-cj-d">
                            <p className="pc-jj-dpt">付款后去哪里查询起名结果？</p>
                            <p className="pc-jj-dpp">在线下单成功支付后，跳转到查看起名结果页面，用户扫描微信公众号二维码关注公众号，在公众号中输入订单号或点击查看“我的订单”按钮就可以查看到相应宝宝起名订单结果详情。</p>

                            <p className="pc-jj-dpt">支付下单后需多久看到结果？</p>
                            <p className="pc-jj-dpp">在线下单成功支付后，系统会立即根据填写的需求内容快速甄选出相应的美名，用户将页面显示的支付单号复制到公众号内就可以查看到宝宝起名订单结果详情。</p>

                            <p className="pc-jj-dpt"><span style={{fontSize:18, color:'red'}}>支付成功后，无法找到订单号，该怎么查看订单结果？</span></p>
                            <p className="pc-jj-dpp">如果无法找到订单号，不必惊慌，只需要打开微信里面“微信支付”，即微信支付凭证，找到相应的微信支付订单通知，查看账单详情中的商品栏，即可以找到相应的订单号、客服服务电话和微信公众号。</p>

                            <p className="pc-jj-dpt">在线下单一定要填写手机么？</p>
                            <p className="pc-jj-dpp">本网站注重用户隐私保护，用户在线下单付款成功后，系统会自动弹窗绑定手机号码的页面，用户可以选择不填写关闭页面，当用户不填写手机号码时，用户查询订单结果的唯一凭证就是用户获取的订单号，如果用户填写了手机号码，用户可以通过手机号码查询订单结果。</p>

                            <p className="pc-jj-dpt">支付成功后，不小心关闭了结果网页或其他原因无法查看到订单号，该怎么查看订单结果？</p>
                            <p className="pc-jj-dpp">如果无法找到订单号，不必惊慌，你只需要使用支付订单的个人微信号关注下面的公众号，然后点击公众号下端“查看订单”按钮，立即可以查看到你成功支付的订单列表。点击相应订单就可以查看到结果信息</p>

                            <p className="pc-jj-dpt">宝宝起名是不是缺什么补什么？</p>
                            <p className="pc-jj-dpp">好的宝宝名，并不是缺什么补什么，一定要结合宝宝的生辰八字信息全面分析。</p>

                            <p className="pc-jj-dpt">名字的笔画为什么和简体字的不同？</p>
                            <p className="pc-jj-dpp">宝宝名字的笔画都是使用康熙字典来计算的，所以和现在的简体字笔画会不同。</p>
                            
                            <p className="pc-jj-dpt">名字不满意怎么办？</p>
                            <p className="pc-jj-dpp">宝妈宝爸由于家庭背景，学历、阅历和喜好的不同，同一个美名可能会有不同印象和满意度，因此，我们的每个订单都提供了100个美名，尽可能提供更多的美名给宝妈宝爸们甄选。</p>

                            <p className="pc-jj-dpt">用户在使用中出现问题，怎么办？</p>
                            <p className="pc-jj-dpp">支付成功后，用户在使用中遇到任何问题都可以通过微信公众号，联系在线客服获取帮助，如果客服没有及时回你，可能是这个时段咨询的人比较多，请耐心等待片刻。在线客服的服务时间：09:00 ~ 22:00</p>
                        </div>
                        <div style={{borderColor:'red', borderWidth:2,marginTop:10, marginBottom:10}}><img src={pc_sysm} alt="none"></img></div>
                        <div className="pc-cj-d">
                            <p className="pc-jj-dpt">为什么不免费开放给用户使用？</p>
                            <p className="pc-jj-dpp">开发一个系统是需要前期研发投入：美工、产品、研发及服务器租用等，上线后，需要研发不断优化系统，运维人员的确保系统能每天24小时正常运行，客服人员售后解决用户遇到的使用问题等，这些都是需要付费人工来完成的。为了能够提供给用户优质的服务，需要向用户收取费用。</p>

                            <p className="pc-jj-dpt">为什么一定要在公众号里才能查看结果？</p>
                            <p className="pc-jj-dpp">为了用户使用方便，本系统不需要注册和登录账号操作，而不使用个人账号的结果就是：用户一旦关闭网页后再次打开就可能不能在看到相应网页了（系统无法识别是谁打开了这个页面）。使用微信公众号，可以避免这种问题，而且通过微信公众号，也方便用户遇到问题后及时与客服沟通。</p>

                            <p className="pc-jj-dpt">付款是否安全，是否被骗？</p>
                            <p className="pc-jj-dpp">现在是法治社会，网站的经营都有严格的管控，本网站是正规网站，已经长期经营多年，而且已经通过了网站备案，用户不用担心这个问题，同时本网站本着诚信经营，为宝妈、宝爸们解决宝宝起名这一重要问题而设立的，不会存在欺骗的，用户有任何问题，请在公众号里联系客服。</p>
                    
                            <p className="pc-jj-dpt">付款后的订单信息保存多久？</p>
                            <p className="pc-jj-dpp">宝宝起名是一个有实效的服务，一旦宝宝名确定了，就不会轻易修改，订单信息就没有任何价值，同时为了保护用户的隐私，本网站的支付起名信息从支付成功到删除信息只保存一年时间，一年后系统自动删除相应的订单信息，信息删除后，无法恢复，请各位宝妈宝爸们知悉。如果有什么信息宝妈宝爸们需要保留的，请用户提前自行截图保存。</p>
                        </div>
                        {/* <div className="pc-qr">
                            <div className="pc-qr-q">
                                <div className="pc-qr-image">
                                    <img src={qrcode} className="pc-qr-image-img " alt="baijiaqm"></img>
                                </div>
                                <div className="pc-qr-image-info">长按二维码，关注公众号，查看取名结果</div>
                            </div>
                        </div> */}
                        <div className="ed-pc">
                            {/* <div className="ed-pc-info"><a className="ed-pc-info" rel="noreferrer" target="_blank" href="aboutme"><span >关于我们</span></a>&nbsp;&nbsp;&nbsp;<span style={{fontWeight:600}}>Copyright©2011-2021</span>&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a className="ed-pc-info" rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn/">蜀ICP备2021028599号</a></div> */}
                            <div className="ed-pc-info"><a className="ed-pc-info" rel="noreferrer" target="_blank" href="aboutme"><span >联系我们</span></a>&nbsp;&nbsp;&nbsp;<span style={{fontWeight:600}}>Copyright©2011-2022</span>&nbsp;&nbsp;版权所有&nbsp;&nbsp;<a className="ed-pc-info" rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备19090915号</a></div>
                            <div className="ed-pc-info">
                                <span >深圳市景辰信息技术有限公司</span>&nbsp;&nbsp;&nbsp;<span >TEL：400-1020732</span>&nbsp;&nbsp;&nbsp;<span >地址：深圳市龙华区大浪街道高峰社区鹊山新村39号3层3180室</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default PCHome;