import React from 'react';
import { Spin, message} from 'antd';
import { HttpPost } from '../common/httpRequest';

import './nameList.css';

class NameList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order_sn:'',
            order_token:'',
            name_list:[],
            loading:true,
            scoreloading:false,
            is_send:false,
            qrcode:null,

        }
        this.timer = null;
        console.log(this.props)
    }

    componentDidMount() {
        if (this.props.order_sn) {
            this.setState({order_sn:this.props.order_sn, order_token:this.props.order_token});
            this.getOrderNameList(this.props.order_sn, this.props.order_token);
        }else if (this.props.location && this.props.location.state && this.props.location.state.order_sn) {
            this.setState({order_sn:this.props.location.state.order_sn, order_token:this.props.location.state.order_token});
            this.getOrderNameList(this.props.location.state.order_sn, this.props.location.state.order_token);
        }else {
            message.error('获取推荐美名列表失败，请联系客服！');
        }
    }

    getOrderNameList = async (order_sn = '', order_token = '') => {
        let postData = {
            order_sn: order_sn,
            order_token: order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/order/order-name-list',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4132) {
                this.setState({name_list: retData.data.nameList})
            }else {
                message.error(retData.message ? retData.message : '获取推荐美名列表失败，请联系客服！');
            }
        }else {
            message.error('获取推荐美名列表失败，请联系客服！');
        }
        this.setState({loading:false});
    }

    scanNameDetail = async (name, id) => {
        this.setState({scoreloading:true});
        let postData = {
            name_id: id,
            order_sn: this.state.order_sn,
            order_token: this.state.order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }
    
        let retData = await HttpPost('/order/name-score',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4146) {
                this.timer = setTimeout(
                () => {
                    this.setState({scoreloading:false});
                    this.props.history.push("/name-detail", {name:name,nameId:id, order_sn: this.state.order_sn, order_token:this.state.order_token});
                },
                1000
                );
            }else {
                this.timer = setTimeout(
                () => {
                    this.setState({scoreloading:false});
                    this.props.history.push("/name-detail", {name:name,nameId:id, order_sn: this.state.order_sn, order_token:this.state.order_token});
                },
                3000
                );
            }
        }else {
            this.timer = setTimeout(
                () => {
                    this.setState({scoreloading:false});
                    this.props.history.push("/name-detail", {name:name,nameId:id, order_sn: this.state.order_sn, order_token:this.state.order_token});
                },
                3000
            );
        }
    }

    componentWillUnmount() {
        this.timer && clearTimeout(this.timer);
    }

    render() {
        return (
            <div className="mb-name-list">
                {this.state.loading ? <Spin tip="Loading..." spinning={this.state.loading}></Spin> :
                <Spin tip="Loading..." spinning={this.state.scoreloading}>
                    <div className="mb-nl-d">
                        {this.state.name_list.length > 0 ? this.state.name_list.map((item) => {
                            if (item.second == '') {
                                return  <div className="mb-nl-nd" key={item.name,item.nid}>
                                            <div className='mb-nld-df'>
                                                <div className="mb-nld-dt">
                                                    <div className="mb-nld-dtp">{item.first_pron}</div>
                                                    <div className="mb-nld-dtw">{item.first}</div>
                                                    <div className="mb-nld-dtb">{item.first_elem}</div>
                                                </div>
                                                <div className="mb-nld-dt">
                                                    <div className="mb-nld-dtp">{item.third_pron}</div>
                                                    <div className="mb-nld-dtw">{item.third}</div>
                                                    <div className="mb-nld-dtb">{item.third_elem}</div>
                                                </div>
                                            </div>
                                            <div  className="mb-nld-dtc" onClick={() => this.scanNameDetail(item.name,item.nid)}>查看详情</div>
                                        </div>
                            }

                            return  <div className="mb-nl-nd" key={item.name,item.nid}>
                                        <div className='mb-nld-d'>
                                            <div className="mb-nld-dt">
                                                <div className="mb-nld-dtp">{item.first_pron}</div>
                                                <div className="mb-nld-dtw">{item.first}</div>
                                                <div className="mb-nld-dtb">{item.first_elem}</div>
                                            </div>
                                            <div className="mb-nld-dt">
                                                <div className="mb-nld-dtp">{item.second_pron}</div>
                                                <div className="mb-nld-dtw">{item.second}</div>
                                                <div className="mb-nld-dtb">{item.second_elem}</div>
                                            </div>
                                            <div className="mb-nld-dt">
                                                <div className="mb-nld-dtp">{item.third_pron}</div>
                                                <div className="mb-nld-dtw">{item.third}</div>
                                                <div className="mb-nld-dtb">{item.third_elem}</div>
                                            </div>
                                        </div>
                                        <div  className="mb-nld-dtc" onClick={() => this.scanNameDetail(item.name,item.nid)}>查看详情</div>
                                    </div>
                        }) : ''}

                    </div>
                </Spin>
                }
            </div>
            
        );
    }
}

export default NameList;