import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import MbHome from './mbter/mbHome';
import Follow from './mbter/follow';
import Result from './mbter/result';
import NameDetail from './mbter/nameDetail';
import Search from './mbter/search';
import AboutMe from './mbter/aboutMe';
import Pay from './mbter/pay';

class MBapp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props)
    }

    render(){
        return(
            <Router>
                <Switch>
                    <Route key='/' path="/" exact component={MbHome}/>
                    <Route key='/pay' path="/pay" exact component={Pay}/>
                    <Route key='/follow' path="/follow" exact component={Follow}/>
                    <Route key='/result' path="/result" exact component={Result}/>
                    <Route key='/search' path="/search" exact component={Search}/>
                    <Route key='/aboutme' path="/aboutme" exact component={AboutMe}/>
                    <Route key='/name-detail' path="/name-detail" exact component={NameDetail}/>
                </Switch>
            </Router>
        );
    }
}

export default MBapp;