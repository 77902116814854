import React,{Component} from "react";
import BScroll from 'better-scroll';

import "./reactDatePicker.css";
 
export default class reactDatePicker extends Component {
    constructor(props){
        super(props);
        this.state = {
            flag:false,
            day:[],
            hour:[],
            minute:[],
            month: ["","01","02","03","04","05","06","07","08","09","10","11","12",""]
        }

        this.old_year = 1970;
        this.select_year = [];
        this.current_year_idx = 2;
        this.select_month = [];
        this.current_month_idx = 2;
        this.select_day = [];
        this.current_day_idx = 2;
        this.select_hour = [];
        this.current_hour_idx = 2;
        this.select_minute = [];
        this.current_minute_idx = 2;
        this.year_warpper = React.createRef();
        this.month_warpper = React.createRef();
        this.day_warpper = React.createRef();
        this.hour_warpper = React.createRef();
        this.minute_warpper = React.createRef();
        this.columnHeight = this.remToPx(0.76);
    }

    componentDidMount(){
        this.initDateInfo();
    }

    componentDidUpdate(){
        this.scrollInit();
    }

    componentWillUnmount(){
        if(this.year_scroll){
            this.year_scroll.destroy();
            this.year_scroll = null;
        }

        if(this.month_scroll){
            this.month_scroll.destroy();
            this.month_scroll = null;
        }

        if(this.day_scroll){
            this.day_scroll.destroy();
            this.day_scroll = null;
        }

        if(this.hour_scroll){
            this.hour_scroll.destroy();
            this.hour_scroll = null;
        }

        if(this.minute_scroll){
            this.minute_scroll.destroy();
            this.minute_scroll = null;
        }
    }

     /**
     * 将rem转化为px
     */ 
     remToPx = (rem)=> {
        let clientWidth = document.documentElement.clientWidth;
        return rem * 100 * (clientWidth / 750) 
    }

    //初始化日期信息
    initDateInfo = () => {
        let maxDay = 31;
        let arrMinute = [];
        let arrHour = [];
        let arrDay = [];
        let arrMonth = [];
        let arrYear = []
        
        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() + 2;

        for(let years = this.old_year; years < year; years++){
            arrYear.push(years.toString());
        }
        arrYear.unshift("");
        arrYear.push("");
        arrYear.unshift("");
        arrYear.push("");

        for (let m = 1; m <= 12; m++) {
            arrMonth.push(m < 10 ? '0' + m.toString() : m.toString());
        }
        arrMonth.unshift("");
        arrMonth.push("");
        arrMonth.unshift("");
        arrMonth.push("");

        maxDay = this.setDayInfo(year, month)
        for (let d = 1; d <= maxDay; d++) {
            arrDay.push(d < 10 ? '0' + d.toString() : d.toString());
        }
        arrDay.unshift("");
        arrDay.push("");
        arrDay.unshift("");
        arrDay.push("");

        for (let h = 0; h < 24; h ++) {
            arrHour.push(h < 10 ? '0' + h.toString() : h.toString());
        }
        arrHour.unshift("未");
        arrHour.unshift("");
        arrHour.push("");
        arrHour.unshift("");
        arrHour.push("");

        for (let u = 0; u < 60; u ++) {
            arrMinute.push(u < 10 ? '0' + u.toString() : u.toString());
        }
        arrMinute.unshift("未");
        arrMinute.unshift("");
        arrMinute.push("");
        arrMinute.unshift("");
        arrMinute.push("");

        this.select_year = arrYear;
        this.select_month = arrMonth;
        this.select_day = arrDay;
        this.select_hour = arrHour;
        this.select_minute = arrMinute;
    }

    setDayInfo = (year, month) => {
        if (month === 2) {
            if (year % 4 === 0) {
                return 29;
            }else {
                return 28;
            }
        }

        if (month < 8) {
            if (month % 2 === 0) {
                return 30;
            }else {
                return 31;
            }
        }else if (month > 7) {
            if (month % 2 === 0) {
                return 31;
            }else {
                return 30;
            }
        }

        return 30;
    }

    scrollInit = () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if(this.props.getModalStatus && !this.year_scroll){
            this.year_scroll = new BScroll(this.year_warpper.current,{
                momentum:false,
                bounceTime:250
            });
            let bottom__distance = -1 * this.columnHeight * (year - this.old_year);
            this.year_scroll.on("scrollEnd",this.scrollHandler.bind(this,this.year_scroll, 1));
            this.year_scroll.scrollTo(0, bottom__distance, 100);
        }
 
        if(this.props.getModalStatus && !this.month_scroll){
            this.month_scroll = new BScroll(this.month_warpper.current,{
                momentum:false,
                bounceTime:250
            });
            console.log(month + 2, 'month + 2')
            let bottom__distance = -1 * this.columnHeight * (month - 1);
            this.month_scroll.on("scrollEnd",this.scrollHandler.bind(this, this.month_scroll, 2));
            this.month_scroll.scrollTo(0, bottom__distance, 100);            
        }

        if(this.props.getModalStatus && !this.day_scroll){
            this.day_scroll = new BScroll(this.day_warpper.current,{
                momentum:false,
                bounceTime:250
            });
            console.log(day + 2, 'day2')
            this.day_scroll.on("scrollEnd",this.scrollHandler.bind(this, this.day_scroll, 3));
            this.day_scroll.scrollTo(0, -1 * this.columnHeight * (day - 1), 100);         
        }

        if(this.props.getModalStatus && !this.hour_scroll){
            this.hour_scroll = new BScroll(this.hour_warpper.current,{
                momentum:false,
                bounceTime:250
            });
            this.hour_scroll.on("scrollEnd",this.scrollHandler.bind(this, this.hour_scroll, 4));
            this.hour_scroll.scrollTo(0, 1 * this.columnHeight * 3, 100);    
        }

        if(this.props.getModalStatus && !this.minute_scroll){
            this.minute_scroll = new BScroll(this.minute_warpper.current,{
                momentum:false,
                bounceTime:250
            });
            this.minute_scroll.on("scrollEnd",this.scrollHandler.bind(this, this.minute_scroll, 5));
            this.minute_scroll.scrollTo(0, 1 * this.columnHeight * 3, 100);
        }
    }

    scrollHandler = (scroll, type)=>{
        let y = Math.abs(scroll.y);

        let columnHeight = this.columnHeight;
 
        let arr = (y / columnHeight).toString().split(".");
 
        let index = parseInt(arr[0]);
 
        if(arr.length === 2 && ("0." + arr[1]) >= 0.5){
            index++;
        }
 
        if(type === 1){ //要计算月份的值
          this.current_year_idx = index + 2;
        }else if(type === 2){
          this.current_month_idx = index + 2;
        }else if(type === 3){
            this.current_day_idx = index + 2;
        }else if(type === 4){
            this.current_hour_idx = index + 2;
        }else if(type === 5){
            this.current_minute_idx = index + 2;
        }
 
        scroll.scrollTo(0, -1 * columnHeight * index, 100);

        this.setState({flag: !this.state.flag});
    }

    ensure = ()=> {
        this.props.getDate(
            this.select_year[this.current_year_idx],
            this.select_month[this.current_month_idx],
            this.select_day[this.current_day_idx],
            this.select_hour[this.current_hour_idx],
            this.select_minute[this.current_minute_idx]
        );
    }
 
   render(){
        return (
         <div className="date_picker">
            <div className="middle_region">
                <div className="middle-bar">
                   <div className="middle-bar-t">请选择出生日期(公历)</div>
                   {/* <i  className="iconfont icon-cha close" onClick={this.props.close}>关闭</i> */}
                </div>
                <div className="middle-title">
                    <span className="middle-title-y"> 年</span>
                    <span className="middle-title-m">月</span>
                    <span className="middle-title-d">日</span>
                    <span className="middle-title-h">时</span>
                    <span className="middle-title-mi">分</span>
                </div>
                <div className="select-time">
                    <div className="select-d select-year">
                        <div>
                          <span className="line_one"></span>
                          <span className="line_two"></span>     
                        </div>
                        <div className="select-year-warpper" ref={this.year_warpper}>
                           <div className="scroll-warper">
                              {
                                  this.select_year.map((v,index)=>{
                                      return (
                                        <div className={this.current_year_idx === index ? "current":"text"} key={index}>{v}</div> 
                                      )
                                  })
                              }                        
                            
                           </div>             
                        </div>                   
                    </div>
                    <div className="select-d select-month">
                        <div>
                            <span className="line_one"></span>
                            <span className="line_two"></span>    
                        </div>
                        <div className="select-month-warpper" ref={this.month_warpper}>
                            <div className="scroll-warper">
                                {this.select_month.map((v,index)=>{
                                    return (
                                    <div className={this.current_month_idx === index ? "current" : "text"} key={index}>{v}</div> 
                                    )
                                })}                                                            
                            </div>                     
                        </div>                   
                    </div>

                    <div className="select-d select-day">
                        <div>
                            <span className="line_one"></span>
                            <span className="line_two"></span>    
                        </div>
                        <div className="select-day-warpper" ref={this.day_warpper}>
                           <div className="scroll-warper">
                              {this.select_day.map((v,index)=>{
                                  return (
                                    <div className={this.current_day_idx === index ? "current" : "text"} key={index}>{v}</div> 
                                  )
                              })}                                                            
                           </div>                     
                        </div>                   
                    </div>
                    <div className="select-d select-hour">
                        <div>
                            <span className="line_one"></span>
                            <span className="line_two"></span>    
                        </div>
                        <div className="select-hour-warpper" ref={this.hour_warpper}>
                           <div className="scroll-warper">
                              {this.select_hour.map((v,index)=>{
                                  return (
                                    <div className={this.current_hour_idx === index ? "current" : "text"} key={index}>{v}</div> 
                                  )
                              })}                                                            
                           </div>                     
                        </div>                   
                    </div>
                    <div className="select-d select-minute">
                        <div>
                            <span className="line_one"></span>
                            <span className="line_two"></span>    
                        </div>
                        <div className="select-minute-warpper" ref={this.minute_warpper}>
                           <div className="scroll-warper">
                              {this.select_minute.map((v,index)=>{
                                  return (
                                    <div className={this.current_minute_idx === index ? "current" : "text"} key={index}>{v}</div> 
                                  )
                              })}                                                            
                           </div>                     
                        </div>                   
                    </div>
                </div>
                <div className="select-bt">
                    <div className="select-cancel" onClick={this.props.close}>取消</div>
                    <div className="select-sure" onClick={this.ensure}>确定</div>
                </div>
            </div>
         </div>
     )
   }
}

