import React from 'react';
import { message } from 'antd';
import { HttpPost } from '../common/httpRequest';

import './search.css';

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword:'',
            order_list:[],
            loading:true,
            is_send:false,

        }
    }

    componentWillMount() {
        this.parseQuery();
    }

    parseQuery = async () => {
        let keyword = '';
        let queryObj = {};

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            // 如果是带#进行路由的PWA项目，有可能会存在下面if这个场景
            if (value.indexOf('#') > -1) {
                value = value.split('#')[0];
            }
            queryObj[key] = value;
        });
        keyword = queryObj.keyword ? queryObj.keyword : '';

        if (keyword.length < 2) {
            message.error('查询订单数据失败，请联系客服');
            return;
        }

        this.setState({keyword: keyword});
        let retData = await HttpPost('/order/order-list', {keyword: keyword});
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4140) {
                this.setState({order_list: retData.data.list});
            } else {
                message.error(retData.message ? retData.message : '查询失败，请联系客服！');
            }
        }else {
            message.error('查询失败，请联系客服！');
        }
    }

    orderDetail = (order_sn, order_token) => {
        this.props.history.push("/result", {order_sn:order_sn, order_token: order_token});
    }

    render() {
        console.log(this.state.order_list)
        return (
            <div className="mb-search">
            <div className="mb-d-d">
                <div className="mb-d-t">
                    <div className="mb-d-tt">我的订单</div>
                </div>
                <div className="mb-d-list">
                {this.state.order_list.length > 0 ? this.state.order_list.map((item) => <div className="mb-dli-st" key={item.order_sn} onClick={() => this.orderDetail(item.order_sn,item.order_token)}>
                    <div className="mb-dli-dlt">
                        <div>
                            <div className="mb-dli-dls">
                                <div className="mb-dli-dltl">姓  氏：</div>
                                <div className="mb-dli-dltr">{item.surname}</div>
                            </div>
                            <div className="mb-dli-dls">
                                <div className="mb-dli-dltl">性  别：</div>
                                <div className="mb-dli-dltr">{item.gender}</div>
                            </div>
                        </div>
                        <div className="mb-dli-dlbt">查看详情</div>
                    </div>
                    
                    <div className="mb-dli-dls">
                        <div className="mb-dli-dlsl">名字类型：</div>
                        <div className="mb-dli-dlsr">{item.name_type}</div>
                    </div>
                    {item.made_type ?
                        <div className="mb-dli-dls">
                        <div className="mb-dli-dlsl">固定用字：</div>
                        <div className="mb-dli-dlsr">{item.made_type}</div>
                    </div> : ''
                    }
                    <div className="mb-dli-dls">
                        <div className="mb-dli-dlsl">生日(公历)：</div>
                        <div className="mb-dli-dlsr">{item.birthday}</div>
                    </div>
                    <div className="mb-dli-dls">
                        <div className="mb-dli-dlsl">订单号：</div>
                        <div className="mb-dli-dlsr">{item.order_sn}</div>
                    </div>
                    <div className="mb-dli-dls">
                        <div className="mb-dli-dlsl">下单时间：</div>
                        <div className="mb-dli-dlsr">{item.created_time}</div>
                    </div>
                    </div>)
                : ''}
                </div>
            </div>
        </div>
        );
    }
}

export default Result;