import React from 'react';
import { Button, message } from 'antd';
import { HttpPost } from '../common/httpRequest';

import m_pbg from '../../images/bg_main.png';
import mb_pay_adv from '../../images/mb_pay_adv.jpg';
import mb_pay_p from '../../images/mb_pay_p.png';

import './pay.css';

class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            is_send:false,
            query:{},
            surname:'',
            order_sn:'',
            order_token:'',
            gender:'',
            birthday:'',
            name_type:'',
            word_type:'',
            money:0,
            code: null,
            state: null,
            openid:null,
            appid:'',
            pay_jupm_url:''
        }

        this.ptimer = null;
        console.log(this.props)
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.order_sn) {
            this.setState({
                surname:this.props.location.state.surname,
                order_sn:this.props.location.state.order_sn,
                order_token: this.props.location.state.order_token,
                gender: this.props.location.state.gender,
                birthday: this.props.location.state.birthday,
                name_type: this.props.location.state.name_type,
                word_type: this.props.location.state.word_type,
                money: this.props.location.state.money
            });
        }else {
            this.parseQuery();
        }
    }

    componentWillUnmount() {
        clearInterval(this.ptimer);
    }

    parseQuery = () => {
        let order_sn = '';
        let queryObj = {};

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            // 如果是带#进行路由的PWA项目，有可能会存在下面if这个场景
            if (value.indexOf('#') > -1) {
                value = value.split('#')[0];
            }
            queryObj[key] = decodeURIComponent(value);
        });

        order_sn = queryObj.order_sn ? queryObj.order_sn : '';
        if (order_sn.length < 3) {
            this.props.history.replace('/');
        }

        this.setState(queryObj);
    }

    getPayStatus = async () => {
        let postData = {
            order_sn: this.state.order_sn,
            order_token: this.state.order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }
        let retData = await HttpPost('/order/pay-status',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4208) {
                window.location = retData.data.jump_url;
            }
        }
    }

    getPayInfo = async () => {
        if (this.state.is_send) {
            return false;
        }

        this.setState({is_send:true, loading:true});
        let postData = {
            order_sn: this.state.order_sn,
            order_token: this.state.order_token,
            code: this.state.code,
            state: this.state.state,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        if (this.state.code && this.state.state) {
            let retData = await HttpPost('/pay/pay-js',postData);
            if (retData && retData.code) {
                if (parseInt(retData.code) === 4204) {
                    function onBridgeReady(){
                        window.WeixinJSBridge.invoke(
                            'getBrandWCPayRequest',
                            retData.data.pay_data,
                            function(res){
                                if(res.err_msg === "get_brand_wcpay_request:ok" ){
                                    window.location = retData.data.jump_url;
                                }else {
                                    alert("支付失败!");
                                    this.setState({is_send:false, loading:false});
                                    
                                    return;
                                }
                            }
                        );
                    }
                    if(typeof WeixinJSBridge == "undefined"){
                        if( document.addEventListener ){
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        }else if (document.attachEvent){
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    }else{
                        onBridgeReady();
                    }

                    this.ptimer = setInterval(() => this.getPayStatus(), 2000);

                    return;
                } else {
                    message.error(retData.message ? retData.message : '获取支付信息失败！');
                }
            }
        }else {
            let retData = await HttpPost('/pay/jump-url',postData);
            console.log(retData);
    
            if (retData && retData.code) {
                if (parseInt(retData.code) === 4204) {
                    this.setState({pay_jupm_url:retData.data.url})
                    window.location = retData.data.url;
                    // window.location = retData.data.url + '&redirect_url=' + encodeURIComponent(redirect_url);
                } else {
                    message.error(retData.message ? retData.message : '获取支付信息失败！');
                }
            }
        }

        this.setState({loading:false, is_send:false});
    }

    render() {
        return (
            <div className="mb-pay">
                <div className="mb-p-d">
                    <div  className="mb-p-adv"><img src={mb_pay_adv} className="mb-p-adv" alt="none"></img></div>
                    <div className="mb-p-t">支付订单信息</div>
                    <div className="mb-p-dt">
                        <div className="mb-p-dls">
                            <div className="mb-p-dlsl">姓  氏：</div>
                            <div className="mb-p-dlsr">{this.state.surname}</div>
                        </div>
                        <div className="mb-p-dls">
                            <div className="mb-p-dlsl">性  别：</div>
                            <div className="mb-p-dlsr">{this.state.gender}</div>
                        </div>
                        <div className="mb-p-dls">
                            <div className="mb-p-dlsl">名字类型：</div>
                            <div className="mb-p-dlsr">{this.state.name_type}</div>
                        </div>
                        {this.state.word_type ?
                            <div className="mb-p-dls">
                            <div className="mb-p-dlsl">固定用字：</div>
                            <div className="mb-p-dlsr">{this.state.word_type}</div>
                        </div> : ''
                        }
                        <div className="mb-p-dls">
                            <div className="mb-p-dlsl">生日(公历)：</div>
                            <div className="mb-p-dlsr">{this.state.birthday}</div>
                        </div>
                        {/* <div className="mb-p-dls">
                            <div className="mb-p-dlsl">订单号：</div>
                            <div className="mb-p-dlsr">{this.state.order_sn}</div>
                        </div> */}
                    </div>
                    <div className="mb-p-db">（立即支付,获取100个吉祥美名）</div>
                    <div  className="mb-p-adv" style={{width:200, height:120,backgroundImage:`url(${m_pbg})`, borderRadius:3}}>
                        <div  className="mb-p-pir" style={{height:120,backgroundImage:`url(${mb_pay_p})`,backgroundSize:'cover'}}>
                            <div className="mb-p-dt-pl">原价:￥198</div>
                            <div className="mb-p-dt-pr"><span className="mb-p-dt-pll">￥</span><span className="mb-p-dt-plr">{this.state.money}</span></div>
                            <div className="mb-p-dt-pa">一生一次，一次一生</div>
                        </div>
                    </div>
                    <div  className="mb-p-de">
                            <Button style={{backgroundColor: '#07c160',height:50,border:0,width:300, fontSize:20}}  type="primary" shape="round" loading={this.state.loading} onClick={() => this.getPayInfo()}>微信支付</Button>
                    </div>
                </div>
            </div>
          );
    }  
}

export default Pay;