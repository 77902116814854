import React from 'react';
import { Spin, message } from 'antd';
import { HttpPost } from '../common/httpRequest';

import './nameAnalysis.css';

class NameAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_info:null,
            birthday_info:null,
            loading:true,
            is_send:false,
            qrcode:null,

        }
        console.log(this.props, 'NameAnalysis')
    }

    componentDidMount() {
        if (this.props.order_sn) {
            this.getOrderResult(this.props.order_sn, this.props.order_token);
        }else if (this.props.location && this.props.location.state && this.props.location.state.order_sn) {
            this.getOrderResult(this.props.location.state.order_sn, this.props.location.state.order_token);
        }else {
            message.error('获取八字详情失败，请联系客服！');
        }
    }

    getOrderResult = async (order_sn = '', order_token = '') => {
        let postData = {
            order_sn: order_sn,
            order_token: order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/order/order-info',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4134) {
                this.setState({user_info: retData.data.userInfo, birthday_info: retData.data.birthdayInfo})
            }else {
                message.error(retData.message ? retData.message : '获取八字详情失败，请联系客服！');
            }
        }else {
            message.error('获取八字详情失败，请联系客服！');
        }

        this.setState({loading:false});
    }

    render() {
        return (
            <div className="mb-name-analysis">
                {this.state.loading ? <Spin tip="Loading..." spinning={this.state.loading}></Spin> :
                (this.state.user_info ? <div className="mb-na-d">
                    <div className="mb-na-dt">
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">姓  氏：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.surname : ''}</div>
                        </div>
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">性  别：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.gender_str : ''}</div>
                        </div>
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">生肖：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.zodiac : ''}</div>
                        </div>
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">起名类型：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.name_type : ''}</div>
                        </div>
                        {this.state.user_info && this.state.user_info.word_type ?
                            <div className="mb-na-dls">
                                <div className="mb-na-dlsl">固定用字：</div>
                                <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.word_type : ''}</div>
                            </div> : ''}
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">生日(公历)：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.birthday : ''}</div>
                        </div>
                        {/* <div className="mb-na-dls">
                            <div className="mb-na-dlsl">生日(农历)：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.birthday_yang : ''}</div>
                        </div> */}
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">生日(阴历)：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.birthday_yin : ''}</div>
                        </div> 
                        <div className="mb-na-dls">
                            <div className="mb-na-dlsl">订单号：</div>
                            <div className="mb-na-dlsr">{this.state.user_info ? this.state.user_info.order_sn : ''}</div>
                        </div>
                    </div>
                    <div className="mb-na-db">
                        <div className="mb-na-db-t">生辰八字</div>
                        <div className="mb-na-db-tt">
                            <table border="1px">
                                <tbody>
                                    <tr>
                                        <td className="mb-na-db-tt-td"></td>
                                        <td className="mb-na-db-tt-td">年柱</td>
                                        <td className="mb-na-db-tt-td">月柱</td>
                                        <td className="mb-na-db-tt-td">日柱</td>
                                        <td className="mb-na-db-tt-td">时柱</td>
                                    </tr>
                                    <tr>
                                        <td className="mb-na-db-tt-td">乾造</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate ? this.state.birthday_info.fate.year : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate ? this.state.birthday_info.fate.month : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate ? this.state.birthday_info.fate.day : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate ? this.state.birthday_info.fate.hour : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="mb-na-db-tt-td">乾造五行</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_elem ? this.state.birthday_info.fate_elem.year : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_elem ? this.state.birthday_info.fate_elem.month : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_elem ? this.state.birthday_info.fate_elem.day : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_elem ? this.state.birthday_info.fate_elem.hour : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="mb-na-db-tt-td">纳音</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_nayin ? this.state.birthday_info.fate_nayin.year : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_nayin ? this.state.birthday_info.fate_nayin.month : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_nayin ? this.state.birthday_info.fate_nayin.day : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.fate_nayin ? this.state.birthday_info.fate_nayin.hour : ''}</td>
                                    </tr>
                                    {this.state.birthday_info && this.state.birthday_info.base_fate ?
                                    <tr>
                                        <td className="mb-na-db-tt-td">本命</td>
                                        <td colspan="4">{this.state.birthday_info ? this.state.birthday_info.base_fate : ''}</td>
                                    </tr> : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mb-na-db">
                        <div className="mb-na-db-t">该八字五行分析</div>
                        <div className="mb-na-db-tt">
                            <table border="1px">
                                <tbody>
                                    <tr>
                                        <td className="mb-na-db-tt-td">五行分析</td>
                                        <td className="mb-na-db-tt-td" style={{paddingLeft:12,paddingRight:12}}>金</td>
                                        <td className="mb-na-db-tt-td" style={{paddingLeft:12,paddingRight:12}}>水</td>
                                        <td className="mb-na-db-tt-td" style={{paddingLeft:12,paddingRight:12}}>木</td>
                                        <td className="mb-na-db-tt-td" style={{paddingLeft:12,paddingRight:12}}>火</td>
                                        <td className="mb-na-db-tt-td" style={{paddingLeft:12,paddingRight:12}}>土</td>
                                    </tr>
                                    <tr>
                                        <td className="mb-na-db-tt-td">含量</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.five_count ? this.state.birthday_info.five_count.gold : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.five_count ? this.state.birthday_info.five_count.water : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.five_count ? this.state.birthday_info.five_count.wood : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.five_count ? this.state.birthday_info.five_count.fire : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.five_count ? this.state.birthday_info.five_count.soil : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="mb-na-db-tt-td">得分</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.gold : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.water : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.wood : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.fire : ''}</td>
                                        <td>{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.soil : ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mb-na-db-t mb-na-db-tt-tdx">该八字喜用分析</div>
                        <div  className="mb-na-db-tx">
                            <div className="mb-na-db-txd">{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.similar : ''}</div>
                            <div className="mb-na-db-txd">{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.heterogeneous : ''}</div>
                            <div className="mb-na-db-txd">{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.comprehensive : ''}</div>
                            <div className="mb-na-db-txd">{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.xi_yong : ''}</div>
                            <div className="mb-na-db-txd">{this.state.birthday_info && this.state.birthday_info.elem_content ? this.state.birthday_info.elem_content.analysis : ''}</div>

                        </div>
                        <div className="mb-na-db-tt">
                            <table border="1px">
                                <tbody>
                                    <tr>
                                        <td className="mb-na-db-tt-td">喜用</td>
                                        <td colSpan={this.state.birthday_info && this.state.birthday_info.fate_use ? this.state.birthday_info.fate_use.length : 1} className="mb-na-db-tt-td">同类</td>
                                        <td colSpan={this.state.birthday_info && this.state.birthday_info.fate_diable ? this.state.birthday_info.fate_diable.length : 1} className="mb-na-db-tt-td">异类</td>
                                    </tr>
                                    <tr>
                                        {this.state.user_info ? <td key="xi" style={{paddingLeft:12,paddingRight:12}}>{this.state.user_info.like_god}</td> : ''}
                                        {this.state.birthday_info && this.state.birthday_info.fate_use ? this.state.birthday_info.fate_use.map((item) => <td key={item} style={{paddingLeft:12,paddingRight:12}}>{item}</td>) : ''}
                                        {this.state.birthday_info && this.state.birthday_info.fate_use ? this.state.birthday_info.fate_diable.map((item) => <td key={item} style={{paddingLeft:12,paddingRight:12}}>{item}</td>) : ''}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mb-na-db-tb">
                        <div className="mb-na-db-tbb" onClick={() => this.props.onNameList()}>立即查看起名结果</div>
                    </div>
                </div> : '')
            }
            </div>
        );
    }
}

export default NameAnalysis;