import React from 'react';
import { Spin, message} from 'antd';
import { HttpPost } from '../common/httpRequest';

import pc_bg_img from '../../images/pc_bg.jpg';
import bg_main from '../../images/bg_main.png';
import pc_lg_adv from '../../images/pc_lg_adv.jpg';
import pc_pay_in from '../../images/pc_pay_in.png';

import './pay.css';

class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            is_send:false,
            qrcode:null,
        }

        this.timer = null;
        console.log(this.props, props)
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.order_sn && this.props.location.state.order_token) {
            this.getPayInfo(this.props.location.state.order_sn, this.props.location.state.order_token);
            this.Carousedo(this.props.location.state.order_sn, this.props.location.state.order_token);
        }else {
            message.error("创建订单信息失败，请重新填写提交");
            this.props.history.goBack(-1);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // 开始轮询
    Carousedo = (order_sn, order_token) => {
        this.timer = setInterval(() => this.getPayStatus(order_sn, order_token), 2000);
    }

    getPayStatus = async (order_sn, order_token) => {
        let postData = {
            order_sn: order_sn,
            order_token: order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/order/pay-status',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 4208) {
                clearInterval(this.timer);
                this.props.history.push("/follow", this.props.location.state);
            }else {
                console.log(retData);
            }
        }
    }

    getPayInfo = async (order_sn, order_token) => {
        if (this.state.is_send) {
            return false;
        }

        this.setState({is_send:true});
        let postData = {
            order_sn: order_sn,
            order_token: order_token,
            timestamp: (Date.parse(new Date()) / 1000)
        }
        console.log('sendData', postData);
        let retData = await HttpPost('/pay/qrcode',postData);
        console.log(retData);

        if (retData && retData.code) {
            if (parseInt(retData.code) === 4204) {
                this.setState({qrcode:retData.data.baseImage, loading:false})
            } else {
                message.error(retData.message ? retData.message : '获取二维码图片失败！');
            }
        }else {
            message.error('获取二维码图片失败');
        }

        this.setState({loading:false, is_send:false});
    }

    render() {
        return (
            <div id="app-pc" className="App-pc">
                <div className="pc-body" style={{backgroundImage:`url(${pc_bg_img})`}}>
                    <div className="pc-bo-c" style={{width:1024,backgroundImage:`url(${bg_main})`}}>
                        <div style={{borderColor:'red', borderWidth:2}}><img src={pc_lg_adv} alt="none"></img></div>
                        <div className="pc-bo-i" >
                            <div className="pc-p-d" style={{width:700,height:605,backgroundImage:`url(${pc_pay_in})`, backgroundSize:700, backgroundRepeat:'no-repeat',}}>
                                <div className="pc-p-dt">
                                    <div v className="pc-p-dl">
                                        <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">姓  氏：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.surname}</div>
                                        </div>
                                        <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">性  别：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.gender}</div>
                                        </div>
                                        <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">生  日：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.birthday}</div>
                                        </div>
                                        <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">名字类型：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.name_type}</div>
                                        </div>
                                        {this.props.location.state.word_type ?
                                            <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">固定用字：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.word_type}</div>
                                        </div> : ''
                                        }
                                        {/* <div className="pc-p-dls">
                                            <div className="pc-p-dlsl">订单号：</div>
                                            <div className="pc-p-dlsr">{this.props.location.state.order_sn}</div>
                                        </div> */}
                                    </div>
                                    <div className="pc-p-dr">
                                        <div className="pc-p-dt-pl">原价:￥198</div>
                                        <div className="pc-p-dt-pr"><span className="pc-p-dt-pll">￥</span><span className="pc-p-dt-plr">{this.props.location.state.money}</span></div>
                                    </div>
                                </div>
                                <div className="pc-p-dm">
                                    <div className="pc-p-dm-t">打开微信,扫描下方支付二维码支付</div>
                                    <div className="pc-p-dm-t">立即获取100个吉祥美名</div>
                                </div>
                                <div  className="pc-p-de">
                                    <div className="pc-p-eq">
                                        <Spin tip="Loading..." spinning={this.state.loading}>
                                            <div><img src={this.state.qrcode ? 'data:image/png;base64,' + this.state.qrcode : ''} className="pc-p-i-img" alt="获取支付二维码图片失败"></img></div>
                                        </Spin>
                                    </div>
                                    <div className="pc-p-et">微信扫一扫,完成支付</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pay;