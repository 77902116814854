import React from 'react';
import ReactDOM from 'react-dom';
import PCapp from './pages/pcapp';
import MBapp from './pages/mbapp';

import './index.css';

import "./rem.js";

const urlparams = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      // 如果是带#进行路由的PWA项目，有可能会存在下面if这个场景
      if (value.indexOf('#') > -1) {
        value = value.split('#')[0];
      }
      vars[key] = value;
    });
    console.log('全局定义js,获取浏览器参数是:',vars);
    return vars;
}

if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || navigator.userAgent.match(/MicroMessenger/i)) {
  //移动端
  ReactDOM.render(
    <React.StrictMode>
      <MBapp urlparams={urlparams()}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
   //pc端
   ReactDOM.render(
    <React.StrictMode>
      <PCapp urlparams={urlparams()}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
