import React from 'react';
import {Radio,Modal,Button} from 'antd';

import NameAnalysis from '../mbter/nameAnalysis';
import NameList from '../mbter/nameList';

import './result.css';

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order_sn:'',
            order_token:'',
            showTips:false,
            type: false,
            tab_key: 'analysis',
            name_list:[],
            loading:true,
            is_send:false,

        }

        console.log(this.props)
    }

    componentWillMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.order_sn) {
            this.setState({order_sn: this.props.location.state.order_sn, order_token: this.props.location.state.order_token, type:true});
            if (this.props.location.state.show_page) {
                this.setState({tab_key: 'namelist'});
            }
        }else {
            this.parseQuery();
        }
    }

    componentDidMount() {
        if (this.state.order_sn === '' || this.state.order_token === '') {
            this.setState({showTips:true});
        }
    }

    jumpFollow = () => {
        this.setState({showtips:false});
        this.props.history.push("/follow");
    }
    
    onTabChange = (key) => {
        this.setState({tab_key: key.target.value});
    };

    onChangeNameList = () => {
        this.setState({tab_key: 'namelist'});
    };

    parseQuery = () => {
        let order_sn = '';
        let order_token = '';
        let queryObj = {};

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            // 如果是带#进行路由的PWA项目，有可能会存在下面if这个场景
            if (value.indexOf('#') > -1) {
                value = value.split('#')[0];
            }
            queryObj[key] = value;
        });
        order_sn = queryObj.order ? queryObj.order : '';
        order_token = queryObj.order ? queryObj.token : '';

        if (order_sn.length < 2) {
            order_sn = this.props.location && this.props.location.state ? this.props.location.state.order_sn : '';
            order_token = this.props.location && this.props.location.state ? this.props.location.state.order_token : '';
        }

        // order_sn = 'GN2021100564373934';
        // order_token = 'om6ye6tExNMhrqXzzbw2P-6QAcsC';

        this.setState({query:queryObj, order_sn: order_sn, order_token: order_token});
    }

    render() {

        return (
            <div className="pc-result">
            <div className="pc-r-d">
                <div>
                    <div className="pc-rd-t">
                        <div className="pc-rb-tt">起名结果</div>
                        <div className="pc-rb-tb">
                            <Radio.Group onChange={this.onTabChange} value={this.state.tab_key} className="mb-r-tbr" style={{borderWidth:0}}>
                                <Radio.Button key="analysis" value="analysis" style={{width:window.screen.width / 2, borderWidth:0,height:40, fontSize:16,paddingTop:5,color: this.state.tab_key === 'analysis' ? '#b27427' : '#fff', backgroundColor: this.state.tab_key === 'analysis' ? '#fff' : '#b27427'}}>五行八字分析</Radio.Button>
                                <Radio.Button key="namelist" value="namelist" style={{width:window.screen.width / 2, borderWidth:0,height:40, fontSize:16,paddingTop:5,color: this.state.tab_key === 'namelist' ? '#b27427' : '#fff', backgroundColor: this.state.tab_key === 'namelist' ? '#fff' : '#b27427'}}>推荐美名</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="mb-r-tab">
                        {this.state.tab_key === 'analysis' ? <NameAnalysis {...this.props} order_sn={this.state.order_sn} order_token={this.state.order_token} onNameList={this.onChangeNameList}/> : <NameList {...this.props} order_sn={this.state.order_sn} order_token={this.state.order_token}/>}
                    </div>
                </div>
                <Modal
                closable={false}
                footer={null}
                title={null}
                width={320}
                visible={this.state.showTips}
            >
                <div className="mak-r">
                    <div className="mak-r-t">
                        <div>提示</div>
                    </div>
                    <div className="mak-r-a">
                        必要数据丢失，获取宝宝起名结果信息失败，请点击下列按钮，通过其他方式查看起名结果。
                    </div>
                    <div className="mak-r-b">
                        <Button type="primary" size="large" shape="round" style={{width:200, border:0, backgroundColor:'#07c160'}} onClick={this.jumpFollow}>查看其他联系方式</Button>
                    </div>
                </div>
            </Modal>
            </div>
        </div>
        );
    }
}

export default Result;